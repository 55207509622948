@import "~@angular/material/prebuilt-themes/purple-green.css";

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: gray;
  margin: 0px;
  padding: 0px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0.85em 1em 0 0.75em !important;
}